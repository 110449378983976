import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
  lightTheme,
  connectorsForWallets,
} from '@rainbow-me/rainbowkit';
import { zenGoWallet } from './connectors/ZenGoWallet';
import { fortmaticWallet } from './connectors/FortmaticWallet';
import { FortmaticConnector } from './connectors/FortmaticConnector';
import { Chain, configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  polygon,
  arbitrum,
  bsc,
  goerli,
  polygonMumbai,
  arbitrumGoerli,
  zkSync,
  polygonZkEvm,
} from 'wagmi/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useDarkMode } from 'hooks/useDarkMode';
import ChainProvider from './ChainProvider';
import { ChainId, chainSrc } from 'constants/constants';
import { ledgerWallet, injectedWallet, okxWallet } from '@rainbow-me/rainbowkit/wallets';
import useSelectedChain from 'hooks/useSelectedChain';

const INFURA_ID = process.env.INFURA_ID || process.env.GATSBY_INFURA_ID;
// TODO: You need to get a wallet connect id from https://cloud.walletconnect.com/:
const WALLET_CONNECT_ID =
  process.env.WALLET_CONNECT_ID || process.env.GATSBY_WALLET_CONNECT_ID;
const FORTMATIC_KEY =
  process.env.FORTMATIC_KEY || process.env.GATSBY_FORTMATIC_KEY;

// Used to remove testnets when in prod build:
const IS_PROD = (process.env.NODE_ENV !== 'development') && (process.env.GATSBY_NODE_ENV !== 'development');

const getChainCustomProps = (chainId: number): ChainCustomProps => {
  switch (chainId) {
    case ChainId.Mainnet:
      return {
        color:
          'linear-gradient(90deg, rgba(60,60,60,1) 0%, rgba(34,34,34,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'png'),
      };
    case ChainId.Goerli:
      return {
        color:
          'linear-gradient(90deg, rgba(0,226,216,1) 0%, rgba(0,181,173,1) 100%)',
        env: 'testnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'png'),
      };
    case ChainId.Polygon:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'svg'),
      };
    case ChainId.Mumbai:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'testnet',
        icon: chainSrc.replace('[chainid]', '137').replace('[ext]', 'svg'),
      };
    case ChainId.Bsc:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'svg'),
      };
    case ChainId.polygonZkEvm:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'png'),
      };
    case ChainId.zkSync:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'png'),
      };
    case ChainId.Arbitrum:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'svg'),
      };
    case ChainId.ArbitrumGoerli:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'testnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'svg'),
      };
    default:
      return {
        color: '',
        env: 'testnet',
        icon: '',
      };
  }
};

const addChainsCustomConfigs = (chains: Chain[]): CustomChain[] => {
  return chains.map((chain) => ({
    ...chain,
    ...getChainCustomProps(chain.id),
  }));
};

const chainsConfiguration = configureChains(
  addChainsCustomConfigs([
    mainnet,
    polygon,
    arbitrum,
    bsc,
    zkSync,
    polygonZkEvm,
    goerli,
    polygonMumbai,
    arbitrumGoerli,
  ]).filter((c) => !(c.env === 'testnet' && IS_PROD)),
  [infuraProvider({ apiKey: INFURA_ID as string }), publicProvider()]
);

export const { chains } = chainsConfiguration;
const { publicClient, webSocketPublicClient } = chainsConfiguration;

const fortmaticConnector = new FortmaticConnector({
  options: { apiKey: FORTMATIC_KEY as string },
  chains: [mainnet, polygon, goerli, polygonMumbai],
});

const { wallets } = getDefaultWallets({
  appName: 'Ichi.org',
  projectId: WALLET_CONNECT_ID as string,
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: 'Available Wallets',
    wallets: [
      ledgerWallet({ projectId: WALLET_CONNECT_ID as string, chains }),
      zenGoWallet({
        projectId: WALLET_CONNECT_ID as string,
        chains,
      }),
      fortmaticWallet({ apiKey: FORTMATIC_KEY as string, chains }),
      injectedWallet({ chains }),
      okxWallet({ projectId: WALLET_CONNECT_ID as string, chains }),
    ],
  },
]);

const wagmiConf = createConfig({
  autoConnect: true,
  connectors: [...connectors(), fortmaticConnector],
  publicClient,
  webSocketPublicClient,
});

const WalletProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <WagmiConfig config={wagmiConf}>
      <ChainProvider>
        <Provider>{children}</Provider>
      </ChainProvider>
    </WagmiConfig>
  );
};

export default WalletProvider;

const Provider: FC<PropsWithChildren> = ({ children }) => {
  const { isDarkMode } = useDarkMode();
  const theme: ReturnType<typeof darkTheme | typeof lightTheme> = useMemo(
    () => (isDarkMode ? darkTheme() : lightTheme()),
    [isDarkMode]
  );
  const { chainId } = useSelectedChain();
  const selectedChain = useMemo(
    () => chains.find((c) => c.id === chainId) || chains[0],
    [chainId]
  );

  return (
    <RainbowKitProvider
      // {initialChain} as is, is a hack, might be fixed in future wagmi/viem/rainbowkit, more details: https://github.com/rainbow-me/rainbowkit/issues/1469
      initialChain={selectedChain}
      chains={chains}
      theme={theme}
    >
      {children}
    </RainbowKitProvider>
  );
};

type ChainCustomProps = {
  color: string;
  env: 'mainnet' | 'testnet';
  icon: string;
};

export type CustomChain = Chain &
  ChainCustomProps & {
    unsupported?: boolean | undefined;
  };
