import { useEffect, useMemo } from 'react';
import { useLocalStorage, useMediaQuery, useUpdateEffect } from 'usehooks-ts';

const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)';
const noop = () => {};
const mockElement = {
  classList: {
    add: noop,
    remove: noop,
  },
};

interface UseDarkModeOutput {
  isDarkMode: boolean;
  toggle: () => void;
  enable: () => void;
  disable: () => void;
}

export function useDarkMode(defaultValue?: boolean): UseDarkModeOutput {
  const isDarkOS = useMediaQuery(COLOR_SCHEME_QUERY);
  const [isDarkMode, setDarkMode] = useLocalStorage<boolean>(
    'darkMode',
    // defaultValue ?? isDarkOS ?? false
    false
  );

  const isBrowser = typeof window !== 'undefined';

  const defaultElement =
    (isBrowser && window.document && window.document.body) || mockElement;

  const getDefaultOnChange =
    (
      element = defaultElement,
      classNameDark = 'dark-mode',
      classNameLight = 'light-mode'
    ) =>
    (val) => {
      element.classList.add(val ? classNameDark : classNameLight);
      element.classList.remove(val ? classNameLight : classNameDark);
    };

  const stateChangeCallback = useMemo(
    () => getDefaultOnChange(),
    [getDefaultOnChange]
  );

  // Update darkMode if os prefers changes
  useUpdateEffect(() => {
    console.log();
    // setDarkMode(isDarkOS);
    setDarkMode(false);
  }, [isDarkOS]);

  useEffect(() => {
    stateChangeCallback(isDarkMode);
  }, [isDarkMode, stateChangeCallback]);

  return {
    isDarkMode,
    toggle: () => setDarkMode((prev) => !prev),
    enable: () => setDarkMode(true),
    disable: () => setDarkMode(false),
  };
}
