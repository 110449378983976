import { darkTheme } from 'common/theme/dark/darkTheme';
import { lightTheme } from 'common/theme/light/lightTheme';
import { createGlobalStyle } from 'styled-components';
// https://icons8.com/icons/set/white-plus
import IconPlusGray from '../common/assets/image/ichi/icons/dark/icon_plus_gray.svg';
import IconPlusBlack from '../common/assets/image/ichi/icons/light/icon_plus_black.svg';
import IconOne1Inch from '../common/assets/image/ichi/oneTokens/logo_256_1inch.svg';
import IconOneBtc from '../common/assets/image/ichi/oneTokens/logo_256_bitcoin.svg';
import IconOneLink from '../common/assets/image/ichi/oneTokens/logo_256_chainlink.svg';
import IconOneEth from '../common/assets/image/ichi/oneTokens/logo_256_eth.svg';
import IconOneFil from '../common/assets/image/ichi/oneTokens/logo_256_filecoin.svg';
import IconOneDODO from '../common/assets/image/ichi/oneTokens/logo_256_oneDODO.svg';
import IconOneFOX from '../common/assets/image/ichi/oneTokens/logo_256_oneFOX.svg';
import IconOneFUSE from '../common/assets/image/ichi/oneTokens/logo_256_oneFUSE.svg';
import IconOnePERL from '../common/assets/image/ichi/oneTokens/logo_256_onePERL.svg';
import IconOneTokens from '../common/assets/image/ichi/oneTokens/logo_256_oneTokens.svg';
import IconOneUni from '../common/assets/image/ichi/oneTokens/logo_256_oneUNI.svg';
import IconUni from '../common/assets/image/ichi/oneTokens/logo_256_uni.svg';
import IconOneVBTC from '../common/assets/image/ichi/oneTokens/logo_256_vBTC.svg';
import IconOneWing from '../common/assets/image/ichi/oneTokens/logo_256_wing.svg';
import IconOneMPH from '../common/assets/image/ichi/oneTokens/onemph-logo.svg';
import IconQuantstamp from '../common/assets/image/ichi/quantstamp.png';
import Icon1Inch from '../common/assets/image/ichi/tokens/1inch.svg';
import Icon1InchTest from '../common/assets/image/ichi/tokens/1inch_test.svg';
import IconBnt from '../common/assets/image/ichi/tokens/bnt.svg';
import IconBntTest from '../common/assets/image/ichi/tokens/bnt_test.svg';
import IconLink from '../common/assets/image/ichi/tokens/chainlink.svg';
import IconFilTest from '../common/assets/image/ichi/tokens/fil_test.svg';
import IconGeneralTest from '../common/assets/image/ichi/tokens/general_test.png';
import IconUniTest from '../common/assets/image/ichi/tokens/uni_test.svg';
import IconUsdc from '../common/assets/image/ichi/tokens/usdc.svg';
import IconUsdcTest from '../common/assets/image/ichi/tokens/usdc_test.svg';
import IconVBTC from '../common/assets/image/ichi/tokens/vBTC.png';
import IconWingTest from '../common/assets/image/ichi/tokens/wing_test.svg';
import ArrowLeftGreen from '../common/assets/image/ichifarm/arrow-left-green.svg';
import ArrowLeftBlack from '../common/assets/image/ichifarm/arrow-left-black.svg';
import { badgeSrc } from 'constants/constants';

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line global-require
//   const { inspect } = require('@xstate/inspect');
//   inspect({
//     // options
//     // url: 'https://statecharts.io/inspect', // (default)
//     iframe: false // open in new window
//   });
// }

export const GlobalStyle = createGlobalStyle`
  html {
    min-width: 375px; // iPhone X width

    /* With Typography this is critical for the site to look correct otherwise there is a double scroll bar */
    /* and the sticky get messed up */
    /* https://stackoverflow.com/questions/13028584/browsers-scrollbar-is-under-my-fixed-div */
    /* https://www.gatsbyjs.com/plugins/gatsby-plugin-typography/ */
    overflow:auto;
    overflow:initial;
  }
  body {
    /* font-family: 'Roboto', sans-serif; */
    font-family: Montserrat;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    box-sizing: border-box;
    /* display: flex;
    justify-content: center; */
    min-width: 375px; // iPhone X width

    background-image: url(assets/image/background.png);
    background-size: cover;
    background-position: center top;
  }

  img {
    // Typography appears to add a margin-bottom: 1.666rem to all img elements
    // no idea why
    margin-bottom: unset;
  }

  /* Margin  */
  & .my-5{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  /* Padding Top */
  & .h-370 {
    height: 370px;
  }
  & .h-275 {
    height: 275px;
  }
  & .h-175 {
    height: 175px;
  }
  & .no-border-bottom {
    border-bottom: none;
  }

  & .white-color {
    color: #ffffff;
  }
  & .cursor-pointer {
    cursor: pointer;
  }

  // overriding sweet alert
  .swal2-popup.swal2-toast {
    box-shadow: none !important;
  }

  // Without this on mobile then the swal is shaded darker due to the modal shading the background
  & .swal2-container {
    z-index: 999999 !important;
  }

  // See FormatNumber, this basically avoids theming and assumes the current colors
  & .loadable-small {
    background-color: inherit;
    border: none;

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.02px;
    line-height: 19px;

    @keyframes load {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    & div.loader {
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-left: 2px solid;
      animation: load 1s infinite linear;
      border-radius: 50%;
      width: 15px;
      height: 15px;
    }


    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  & .page-btn{
    text-align: center;

    cursor: pointer;
    height: 50px;
    min-width: 120px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.02px;
    line-height: 19px;
    text-align: center;
    border: none;
    border-radius: 10px;

    @media screen and (max-width: 800px) {
      min-width: 80px;

      & span{
        height: 38px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }

  }

  & .loadable-default {

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.02px;
    line-height: 19px;

    @keyframes load {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    & div.loader {
      border: 4px solid rgba(255, 255, 255, 0.2);
      border-left: 4px solid;
      animation: load 1s infinite linear;
      border-radius: 50%;
      width: 25px;
      height: 25px;
    }


    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  /* ------------------------------------- */
  /* Web3 Modal css overrides, original intention here*/
  /* is to make the ZenGo icon bigger */
  /* ------------------------------------- */
  .web3modal-provider-icon {
    width: 75px !important;
    height: 75px !important;

    /*
    @media screen and (max-width: 768px) {
        width: 12vw;
        height: 12vw;
    }
    */
  }

  /* ------------------------------------- */
  /* Non-themed icons  */
  /* ------------------------------------- */
  & .icon-oneeth {
    content: url(${IconOneEth});
  }
  & .icon-oneETH {
    content: url(${IconOneEth});
  }
  & .icon-onebtc {
    content: url(${IconOneBtc});
  }
  & .icon-oneBTC {
    content: url(${IconOneBtc});
  }
  & .icon-1inch {
    content: url(${Icon1Inch});
  }
  & .icon-link {
    content: url(${IconLink});
  }
  & .icon-onelink {
    content: url(${IconOneLink});
  }
  & .icon-oneLINK {
    content: url(${IconOneLink});
  }
  & .icon-onewing {
    content: url(${IconOneWing});
  }
  & .icon-oneWING {
    content: url(${IconOneWing});
  }
  & .icon-onevbtc {
    content: url(${IconOneVBTC});
  }
  & .icon-oneVBTC {
    content: url(${IconOneVBTC});
  }
  & .icon-usdc {
    content: url(${IconUsdc});
  }
  & .icon-USDC {
    content: url(${IconUsdc});
  }
  & .icon-vbtc {
    content: url(${IconVBTC});
  }
  & .icon-bnt {
    content: url(${IconBnt});
  }
  & .icon-bpt {
    content: url(${IconOneTokens});
  }
  & .icon-uni {
    content: url(${IconUni});
  }
  & .icon-oneuni {
    content: url(${IconOneUni});
  }
  & .icon-oneUNI {
    content: url(${IconOneUni});
  }
  & .icon-onefil {
    content: url(${IconOneFil});
  }
  & .icon-oneFIL {
    content: url(${IconOneFil});
  }
  & .icon-one1inch {
    content: url(${IconOne1Inch});
  }
  & .icon-one1INCH {
    content: url(${IconOne1Inch});
  }
  & .icon-onefuse {
    content: url(${IconOneFUSE});
  }
  & .icon-onemph {
    content: url(${IconOneMPH});
  }
  & .icon-oneperl {
    content: url(${IconOnePERL});
  }
  & .icon-onedodo {
    content: url(${IconOneDODO});
  }
  & .icon-onefox {
    content: url(${IconOneFOX});
  }
  & .arrow-left-green {
    content: url(${ArrowLeftGreen});
  }
  & .arrow-left-black {
    content: url(${ArrowLeftBlack});
  }
  & .icon-quantstamp {
    content: url(${IconQuantstamp});
    // padding-top: 20px;
  }

  // --------------------------------
  // NOTE: For Kovan testing
  // --------------------------------
  & .icon-OTI {
    content: url(${IconGeneralTest});
  }
  & .icon-oti {
    content: url(${IconGeneralTest});
  }
  & .icon-test_onefil {
    content: url(${IconFilTest});
  }
  & .icon-test_oneFIL {
    content: url(${IconFilTest});
  }
  & .icon-test_oneuni {
    content: url(${IconUniTest});
  }
  & .icon-test_oneUNI {
    content: url(${IconUniTest});
  }
  & .icon-weenus {
    content: url(${IconGeneralTest});
  }
  & .icon-Token6 {
    content: url(${IconGeneralTest});
  }
  & .icon-test_1inch {
    content: url(${Icon1InchTest});
  }
  & .icon-test_bnt {
    content: url(${IconBntTest});
  }
  & .icon-test_fil {
    content: url(${IconFilTest});
  }
  & .icon-test_uni {
    content: url(${IconUniTest});
  }
  & .icon-test_usdc {
    content: url(${IconUsdcTest});
  }
  & .icon-test_USDC {
    content: url(${IconUsdcTest});
  }
  & .icon-test_wing {
    content: url(${IconWingTest});
  }

  & .bril-logo-main{
    content:url('../../assets/image/Bril_Logo_Bril_Logo_Black.svg');
  }

  & .bril-logo-white {
    content:url('../../assets/image/Bril_Logo_Bril_Logo_White.svg');
  }
  
  & .styled-claim-rewards-button {
    margin-top: 10px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    padding: 7px 20px;
    min-width: 120px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.01px;
    line-height: 15px;
  }

  & .position-relative {
    position: relative;
  }

  & .sub-text-size {
    font-size: .75em;
  }

  /* ---------------------------------- */
  /* -------- General ----------------- */
  /* ---------------------------------- */

  & .text-align-left {
    text-align: left;
  }
  & .text-align-right {
    text-align: right;
  }

  & .value-text {
    height: 24px;
    font-size: 20px;
    letter-spacing: 0.02px;
    line-height: 24px;
  }

  & .padding-1rem{
    padding: 1rem;
  }

  & .box-footer{
    border-radius: 0 0 10px 10px;
    // box-shadow: 0 0 40px 0 rgba(0, 56, 124, 0.1);

    & button {
      cursor: pointer;
      // border: none;
      height: 40px;
      width: 160px;
      border-radius: 10px;

      & span {
        height: 19px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.02px;
        line-height: 19px;
        text-align: center;
      }

      @media screen and (max-width: 800px) {
        height: 64px;
      }
      @media screen and (max-width: 540px) {
        width: 118px;
      }
    }
  }

  & .button-secondary {
    height: 30px;
    width: 90px;
    border: none;
    font-size: 12px;
    font-weight: 500;
    border-radius: 8px;
  }


  & .vault-button, .selected-vault-button{
    display: flex;
    flex-direction: row;
    min-width: 120px;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 5px 10px;
    border: 1px solid #ccf2e5;
    background-color: transparent;
  }
  .selected-vault-button{
    background-color: #00BD7B;
  }

  & .error {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
    /* TODO: THEME */
    background-color: rgba(230, 34, 123, 0.1);
    color: #e6227b;
    text-align: center;
    & a{
      color: #e6227b;
    }
  }

  &  .success {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
    text-align: center;
  }

  // --------------------------------------------------
  // Tables
  // --------------------------------------------------
  & table {
    width: 100%;
    border-collapse: collapse;

  }

  & div.table-vaults-wrapper {
    height: 75vh !important;

    @media screen and (max-height: 1620px) {
      height: 70vh !important;
    }
    @media screen and (max-height: 1340px) {
      height: 65vh !important;
    }
    @media screen and (max-height: 1150px) {
      height: 60vh !important;
    }
    /* When the screen is up to 900px and not bigger*/
    @media screen and (max-height: 1010px) {
      height: 55vh !important;
    }
    @media screen and (max-height: 900px) {
      height: 50vh !important;
    }
    @media screen and (max-height: 810px) {
      height: 45vh !important;
    }
    @media screen and (max-height: 730px) {
      height: 40vh !important;
    }
  }
  & div.table-vaults-wrapper::-webkit-scrollbar{
    -webkit-appearance: none;
    width: 10px !important;
  }
  & div.table-vaults-wrapper::-webkit-scrollbar-vertical{
    //  opacity:0;
    //  background: transparent;
    width: 10px !important;
  }
  & div.table-vaults-wrapper::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0, .4) !important;
    border-radius: 4px;
    border: 1px solid #ffffff;
  }
  & div.table-vaults-wrapper::-webkit-scrollbar-track{
    border-radius: 4px;
    background-color: rgba(128, 128, 128, .4);
  }

  .vault-paging {
    text-align: center;
  }

  & th {
    height: 18px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.01px;
    line-height: 18px;
    padding-bottom: 5px;

    // for tooltip icon
    & img {
      margin-left: 5px;
      height: 14px;
      width: 14px;
    }
  }

  & td {
    padding: 20px 12px;
    vertical-align: middle;
      @media screen and (max-width: 900px) {
        padding: 12px 12px;
      }
    }

  & .table-wrapper {
    padding-top: 24px;
    overflow: auto !important;
    height: 100%;
    width: 100%;
  }

  // TABS
  & .rc-tabs-nav-wrap {
    padding: 20px;

    & .rc-tabs-nav-list {
      display: flex;
    }
  }

  // TODO: Work on styling here, not good
  .rc-tabs {
    border: 0;
    /* max-width: 360px; */
    // margin: 44px 0 0;
    box-sizing: border-box;
    position: relative;
    @media only screen and (max-width: 991px) {
      max-width: 100%;
      margin-top: 0px;
    }
    .rc-tabs-bar {
      margin-left: 15px;
    }
    .rc-tabs-nav-wrap {
      padding: 0;
      .rc-tabs-tab-prev,
      .rc-tabs-tab-next {
        display: none;
      }
      .rc-tabs-nav-scroll,
      .rc-tabs-nav,
      .rc-tabs-nav-list {
        width: 100%;
        .rc-tabs-tab {
          width: 50%;
          margin-right: 0;
          padding: 13px 0;
          text-align: center;
          box-sizing: border-box;
          position: relative;
          display: block;
          transition: color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
          cursor: pointer;
          float: left;
          height: 100%;
          font-weight: 500;
          font-size: 14px;
          line-height: 1.5;
          white-space: nowrap;
          &.rc-tabs-tab-active {
            cursor: default;
            transform: translateZ(0);
          }
        }
        .rc-tabs-ink-bar {
          height: 2px !important;
          bottom: 0;
          left: 0;
          z-index: 1;
          position: absolute;
          box-sizing: border-box;
          margin-top: -3px;
          transform-origin: 0 0;
          width: 0;
          height: 0;
          }
          .rc-tabs-ink-bar-animated {
            transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1),
              left 0.3s cubic-bezier(0.35, 0, 0.25, 1),
              top 0.3s cubic-bezier(0.35, 0, 0.25, 1),
              height 0.3s cubic-bezier(0.35, 0, 0.25, 1),
              width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
          }
      }
      }
      .rc-tabs-tabpane {
      /* padding-left: 15px;
      padding-bottom: 15px;
      padding-right: 15px; */
      /* @media (min-width: 1200px) {
          min-height: 560px;
      } */
    }
  }


  .mint-redeem-box{
    margin: auto;
    min-height: 300px;
    width: 400px;
    border-radius: 26px;

    @media screen and (max-width: 500px) {
      width: 100%;
    }

  }

  /* ---------------------------------- */
  /* -------- Dark Mode --------------- */
  /* ---------------------------------- */

  &.dark-mode {

    background-color: ${darkTheme.colors.backgroundColor};
    /* transition: background-color .5s ease; */


    input {
      caret-color: ${darkTheme.colors.inputPlaceholderColor};
      color: ${darkTheme.colors.inputPlaceholderColor};
    }

    /* ------------------------------------- */
    /* Wallet  */
    /* ------------------------------------- */
    .wallet-button-bg-color {
      background-color: ${darkTheme.colors.walletButtonBgColor};
    }
    .wallet-etherscan-color {
      color: ${darkTheme.colors.sidebarItemSelectedColor};
    }
    .wallet-container {
      background-color: ${darkTheme.colors.contentInnerBgColor};
      border: 2px solid transparent;
      &.active {
        border: 2px solid #026DEF;
      }
    }
    .wallet-popper-inner-bg-color {
      background-color: ${darkTheme.colors.contentInnerInnerBgColor};
    }
    .wallet-name {
      color: ${darkTheme.colors.subTextColor};
    }
    .wallet-eth {
      background-color: #838BA8;
      // border: 2px solid ${darkTheme.colors.backgroundColor};
      // background: linear-gradient(315deg, #60658B 0%, #878DAC 100%);
    }
    .wallet-matic {
      // border: 2px solid ${darkTheme.colors.backgroundColor};
      background: rgb(130, 71, 229);
      // background: linear-gradient(90deg, rgba(179, 138, 249, 1) 0%, rgba(130, 71, 229, 1) 100%);
    }

    /* ------------------------------------- */
    /* General  */
    /* ------------------------------------- */
    & .styled-hamburger {
      rect {
        stroke: ${darkTheme.colors.hamburgerIcon};
        fill: ${darkTheme.colors.hamburgerIcon};
      }
    }

    & .styled-fixed-sidebar {
      background-color: ${darkTheme.colors.sidebarBackgroundColor};
      box-shadow: 0 4px 20px #00000016;
      /* transition: background-color .5s ease; */
    }

    & .styled-content {
      background-color: ${darkTheme.colors.contentBgColor};
    }

    & .content-inner-bg-color {
      background-color: ${darkTheme.colors.contentInnerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .content-inner-inner-bg-color {
      background-color: ${darkTheme.colors.contentInnerInnerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .announcement-bg-color {
      background-color: ${darkTheme.colors.annoncementBgColor};
      /* transition: background-color .5s ease; */
    }

    & .primary-bg-color {
      background-color: ${darkTheme.colors.primary};
      /* transition: background-color .5s ease; */
    }

    & .primary-color {
      color: ${darkTheme.colors.primary};
      /* transition: color .5s ease; */
    }

    & .primary-text-color {
      color: ${darkTheme.colors.primaryTextColor};
      /* transition: color .5s ease; */
    }

    & .warning-text-color {
      color: ${darkTheme.colors.warningTextColor};
      /* transition: color .5s ease; */
    }

    & .sub-text-color {
      color: ${darkTheme.colors.subTextColor};
      /* transition: color .5s ease; */
    }

    & .heading-color {
      color: ${darkTheme.colors.headingColor};
      /* transition: color .5s ease; */
    }

    & .selected-color {
      color: ${darkTheme.colors.selectedColor};
      border-color: ${darkTheme.colors.selectedColor};
    }

    & .footer-bg-color {
      background-color: ${darkTheme.colors.footerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .primary-button-bg-color {
      background-color: ${darkTheme.colors.primaryButtonBgColor};
      border-color: ${darkTheme.colors.primaryButtonBgColor};
      /* transition: background-color .5s ease; */
    }
    & .primary-button-disabled-bg-color {
      background-color: ${darkTheme.colors.primaryButtonDisabledBgColor} !important;
      cursor: not-allowed;
      /* transition: background-color .5s ease; */
    }
    & .primary-button-color {
      color: ${darkTheme.colors.white};
      /* transition: color .5s ease; */
    }
    & .clean-style-button {
      background-color: transparent;
      border: none;
    }

    & .secondary-button-bg-color {
      background-color: ${darkTheme.colors.primary};
      border: 1px solid ${darkTheme.colors.primary};
    }
    & .secondary-button-bg-color:hover {
      border: 1px solid ${darkTheme.colors.white};
    }
    & .secondary-button-color {
      color: ${darkTheme.colors.white};
      /* transition: color .5s ease; */
    }

    & .secondary-button{
      background-color: ${darkTheme.colors.backgroundColor};
      color: ${darkTheme.colors.ichiBalanceButtonBgColor};
    }

    & .secondary-button-disabled{
      background-color: ${darkTheme.colors.backgroundColor};
      color: ${darkTheme.colors.ichiBalanceButtonBgColor};
      opacity: 60%;
      cursor: not-allowed;
    }

    & .deposit-button{
      background-color: ${darkTheme.colors.ichiBalanceButtonBgColor};
      color: ${darkTheme.colors.white};
    }

    & .nav-button-bg-color {
      background-color: rgb(15, 40, 76);
      border: 1px solid rgb(15, 40, 76);
      color: white;
    }

    & .inner-box-bg-color {
      background-color: ${darkTheme.colors.modalBgColor};
      /* transition: background-color .5s ease; */
    }
    & .inner-box-shadow, .inner-box {
      background-color: ${darkTheme.colors.modalBgColor};
      box-shadow: none;
      /* transition: background-color .5s ease; */
    }

    & .hr-bg-color {
      background-color: ${darkTheme.colors.hrBgColor};
      /* transition: background-color .5s ease; */
    }

    & .text-spinner {
      & div.loader {
        border: 4px solid rgba(255, 255, 255, 0.2);
        border-left: 4px solid;
        animation: load 1s infinite linear;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        color: ${darkTheme.colors.spinnerColor};
      }
    }

    & .topbar-bg-color {
      background-color: ${darkTheme.colors.backgroundColor};
      /* transition: background-color .5s ease; */
    }

    & .sub-text{
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.01px;
      line-height: 15px;
      color: ${darkTheme.colors.subTextColor};
    }

    /* ------------------------------------- */
    /* Elements  */
    /* ------------------------------------- */
    & .section-description {
      display: flex;
      justify-content: left;
      margin: 20px 0;
      border-radius: 8px;
      padding: 30px;
      background-color: ${darkTheme.colors.contentInnerBgColor};
      color: ${darkTheme.colors.primaryTextColor};
    }

    & .error-message{
      background-color: rgba(230, 34, 123, 0.1);
      color: #e6227b;
    }

    /* ------------------------------------- */
    /* Tooltips  */
    /* ------------------------------------- */
    & .tooltip-top {
      bottom: 100%;
      padding-bottom: 9px;

      &::after {
        border-top: 9px solid ${darkTheme.colors.inputBorderColor};
        bottom: 0;
      }
    }

    & .tooltip-bottom {
      top: 100%;
      padding-top: 9px;

      &::after {
        border-bottom: 9px solid ${darkTheme.colors.inputBorderColor};
        top: 0;
      }
    }

    & .tooltip-left {
      right: 100%;
      padding-right: 9px;

      &::after {
        border-left: 9px solid ${darkTheme.colors.inputBorderColor};
        right: 0;
      }
    }

    & .tooltip-right {
      left: 100%;
      padding-left: 9px;

      &::after {
        border-right: 9px solid ${darkTheme.colors.inputBorderColor};
        left: 0;
      }
    }

    & .tooltip-bubble {
      color: ${darkTheme.colors.white};
      background-color: ${darkTheme.colors.contentBgColor};
    }

    & .tooltip-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      flex: 0 0 14px;
      @media screen and (max-width: 970px) {
        display: none;
      }
      & img {
        margin-left: 0px;
      }
    }

    & .tooltip-message {
      background-color: ${darkTheme.colors.modalBgColor};
      border: ${darkTheme.colors.inputBorder};
      color: white;
      /* This looks terrible in dark theme, commenting for now */
      /*box-shadow: ${darkTheme.colors.primaryBoxShadow};*/
    }

    /* ------------------------------------- */
    /* tabs  */
    /* ------------------------------------- */
    & .tabs-container {
      & .rc-tabs-nav-wrap {
        border-bottom: 1px solid ${darkTheme.colors.modalBgColor};
        &  .rc-tabs-tab {
          color: ${darkTheme.colors.inactiveTabColor};
          &.rc-tabs-tab-active {
            color: ${darkTheme.colors.activeTabColor};
          }
        }
        & .rc-tabs-ink-bar {
          background: ${darkTheme.colors.sidebarItemSelectedColor};
        }
      }

      // This hides the 'More' button on Firefox
      & .rc-tabs-nav-operations {
        & .rc-tabs-nav-more {
          display: none;
        }
      }
    }
    & .btn-view-all {
      color: ${darkTheme.colors.btnViewAllColor};
    }

    /* ------------------------------------- */
    /* Table  */
    /* ------------------------------------- */
    & .table-border-bottom, .table-border-bottom td {
      border-bottom: ${darkTheme.colors.tableBorderBottom};
      /* transition: border-bottom .5s ease; */
    }
    & .treasury-table-sub-text-two-color {
      color: ${darkTheme.colors.treasuryTableSubTextTwoColor};
      /* transition: color .5s ease; */
    }

    & .table-vaults {
      & thead {
        background-color: ${darkTheme.colors.backgroundColor};
      }
      & tr td {
        border-bottom: ${darkTheme.colors.tableBorderBottom};
      }
    }

    & .bordered {
      border: ${darkTheme.colors.tableBorderBottom};
      /* transition: border-bottom .5s ease; */
    }

    /* ------------------------------------- */
    /* ICHI Balance  */
    /* ------------------------------------- */
    & .ichi-balance-bg-color{
      background-color: ${darkTheme.colors.ichiBalanceBgColor};
      /* transition: background-color .5s ease; */
    }
    & .ichi-balance-header-border-bottom {
      border-bottom: ${darkTheme.colors.ichiBalanceHeaderBorderBottom};
      /* transition: border-bottom .5s ease; */
    }
    & .ichi-balance-header-text-color {
      color: ${darkTheme.colors.ichiBalanceHeaderTextColor};
      /* transition: color .5s ease; */
    }
    & .ichi-balance-button-bg-color {
      background-color: ${darkTheme.colors.ichiBalanceButtonBgColor};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* search  */
    /* ------------------------------------- */
    & .input-border {
      border: ${darkTheme.colors.inputBorder};
      /* transition: border .5s ease; */
    }
    & .input-bg-color {
      background-color: ${darkTheme.colors.inputBgColor};
      /* transition: background-color .5s ease; */
    }
    & .input-label-color {
      color: ${darkTheme.colors.inputLabelColor};
      /* transition: color .5s ease; */
    }
    & .input-placeholder-color {

      &::placeholder {
        color: ${darkTheme.colors.inputPlaceholderColor};
        /* transition: color .5s ease; */
      }
    }

    /* ------------------------------------- */
    /* Bottombar  */
    /* ------------------------------------- */
    & .styled-bottombar {
      background-color: ${darkTheme.colors.backgroundColor};
    }

    /* ------------------------------------- */
    /* Sidebar  */
    /* ------------------------------------- */
    & .styled-top-item {
      & a {
        color: ${darkTheme.colors.sidebarTextColor};
        /* transition: color .5s ease; */
        &.active {
          color: ${darkTheme.colors.sidebarTextSelectedColor};
          /* transition: color .5s ease; */
        }
      }
    }

    & .styled-bottom-items {
      border-top: 1px solid ${darkTheme.colors.borderColor};
      & a {
        color: ${darkTheme.colors.sidebarTextColor};
        /* transition: color .5s ease; */
      }
    }

    /* ------------------------------------- */
    /* Farm  */
    /* ------------------------------------- */
    & .coin-name {
      display: flex;
      margin-top: 0px;
      height: 17px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.02px;
      line-height: 19px;
      white-space: nowrap;
      color: ${darkTheme.colors.primaryTextColor};
    }

    & .sub-name {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.26px;
      line-height: 19px;
      color: ${darkTheme.colors.primaryTextColor};
    }

    & .styled-go-back {
      margin-top: 10px;

      & a {
        height: 20px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.02px;
        line-height: 19px;
        color: ${darkTheme.colors.primary};
      }
    }

    & .vault-header {
      margin: auto; // centers the div
      width: 550px;
      background-color: ${darkTheme.colors.contentBgColor};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 970px) {
        margin-top: 10px;
      }
      @media screen and (max-width: 610px) {
        width: 100%;
      }
    }

    & .vault-title {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.02px;
      line-height: 26px;
      white-space: nowrap;
      color: ${darkTheme.colors.primaryTextColor};
    }

    & .vault-box{
      margin: auto;
      margin-top: 20px;
      width: 600px;
      border-radius: 10px;

      @media screen and (max-width: 610px) {
        width: 100%;
      }
    }

    & .vault-description{
      margin: 20px auto;
      display: block;
      max-width: 600px;

      @media screen and (max-width: 610px) {
        width: 100%;
      }
    }

    & .bg-bf{
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(269.63deg, #FFE1B7 4.13%, #FFF4D2 64.07%, #FFF9DB 99.84%);
      gap: 4px;
      border-radius: 30px;
      color: black;
    }

    & .bg-image-bf{
      background-color: ##89886C;
      background-image: url('../../assets/image/Gradient Bg.png');
      background-size: 100% 100%;
    }

    & .chainlink-logo{
      content:url('${badgeSrc}Chainlink-white.svg');
    }
    & .bril-logo{
      content:url('../../assets/image/bril-logo-white.svg');
    }
    & .yield-iq-logo{
      content:url('${badgeSrc}Yield-IQ-Inverted-Color.png');
    }
    & .yield-iq-div{
      background-color: #ffffff;
    }

    & .styled-farm-sub-header-text {
      color: ${darkTheme.colors.headingColor};
      /* transition: color .5s ease; */
    }
    & .styled-no-rewards-button {
      background-color: ${darkTheme.colors.noRewardsButtonColor};
      /* transition: background-color .5s ease; */
    }
    & .styled-claim-rewards-button-color {
      background-color: ${darkTheme.colors.primaryButtonBgColor};
      /* transition: background-color .5s ease; */
    }
    & .vault-banner-border {
      border: 1px solid ${darkTheme.colors.vaultBannerBorderColor};
      box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    }

    /* ------------------------------------- */
    /* Treasury  */
    /* ------------------------------------- */
    & .treasury-position-box {
      border: ${darkTheme.colors.treasuryPositionBorder};
      background-color: ${darkTheme.colors.treasuryPositionBgColor};
    }
    & .treasury-position-name-color {
      color: ${darkTheme.colors.treasuryPositionNameColor};
      /* transition: color .5s ease; */
    }

    /* ------------------------------------- */
    /* Icons  */
    /* ------------------------------------- */
    & .icon-plus {
      content: url(${IconPlusGray});
    }
    & .icon-theme-switcher {
      content: url(${darkTheme.icons.theme});
      width: 30px;
      height: 30px;
    }
    & .icon-theme-switcher-mobile {
      content: url(${darkTheme.icons.themeMobile});
      width: 60px;
      height: 60px;
      @media (min-width: 799px) {
        display: none;
      }
    }
    & .icon-preferences-active {
      content: url(${darkTheme.icons.preferences.active});
    }
    & .icon-preferences {
      content: url(${darkTheme.icons.preferences.inactive});
      &.active {
        content: url(${darkTheme.icons.preferences.active});
      }
      @media (max-width: 800px) {
        display: none;
      }
    }
    & .icon-preferences-mobile {
      content: url(${darkTheme.icons.preferences.inactiveMobile});
      width: 80px;
      height: 80px;
      @media (min-width: 799px) {
        display: none;
      }
    }
    & .icon-new-window {
      content: url(${darkTheme.icons.newWindow});
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      flex: 0 0 15px;
      margin-left: 5px;
      // margin-bottom: 0;
    }
    & .icon-new-window-white {
      content: url(${darkTheme.icons.newWindowWhite});
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      flex: 0 0 15px;
      margin-left: 5px;
      // margin-bottom: 0;
    }
    & .icon-close {
      content: url(${darkTheme.icons.close});
    }
    & .icon-ichi-owned {
      content: url(${darkTheme.icons.ichiOwned});
    }
    & .icon-ichi {
      content: url(${darkTheme.icons.ichiOwned});
    }
    & .icon-ichi-mobile {
      content: url(${darkTheme.icons.ichiWhite});
      height: 34px;
      width: 34px;
    }
    & .icon-lp {
      content: url(${darkTheme.icons.lp});
    }
    & .icon-eth {
      content: url(${darkTheme.icons.eth});
    }
    & .icon-eth-wallet {
      content: url(${darkTheme.icons.ethWhite});
    }
    & .icon-matic-wallet {
      content: url(${darkTheme.icons.maticWhite});
      margin-right: 3px;
    }
    & .icon-Token18 {
      content: url(${darkTheme.icons.eth});
    }
    & .icon-WETH {
      content: url(${darkTheme.icons.eth});
    }
    & .icon-weth {
      content: url(${darkTheme.icons.eth});
    }
    & .icon-wbtc {
      content: url(${darkTheme.icons.wbtc});
    }
    & .icon-renFIL {
      content: url(${darkTheme.icons.renFIL});
    }
    & .icon-test-renfil {
      content: url(${darkTheme.icons.renFIL});
    }
    & .icon-help {
      content: url(${darkTheme.icons.help});
    }
    // --------------------------------
    // NOTE: For Kovan testing
    // --------------------------------
    & .icon-test_ichi {
      content: url(${darkTheme.icons.ichiOwned});
    }
    & .icon-test_weth {
      content: url(${darkTheme.icons.eth});
    }

    /* ------------------------------------- */
    /* Wallet Popper  */
    /* ------------------------------------- */
    & .wallet-popper-header-border-bottom {
      border-bottom: ${darkTheme.colors.walletPopperHeaderBorderBottom};
      /* transition: border-bottom .5s ease; */
    }
    & .wallet-popper-text-color {
      background-color: ${darkTheme.colors.walletPopperTextColor};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* Select Token */
    /* ------------------------------------- */
    & .styled-select-token {
      color: ${darkTheme.colors.subTextColor};
      border: none;
      & select {
        background-color: ${darkTheme.colors.contentInnerBgColor};
        /* transition: background-color .5s ease; */
      }

      & .select__control {
        background-color: ${darkTheme.colors.contentInnerBgColor};
        /* transition: background-color .5s ease; */

        &:hover {
          border: none;
          box-shadow: none;
        }
      }

      & .select__menu {

        // This seems to be the outside border/container of the actual dropdown
        & .select__menu-list {
          background-color: ${darkTheme.colors.contentInnerBgColor};
        }

        // This is each item in the actual dropdown
        & .select__option {
          background-color: ${darkTheme.colors.contentInnerBgColor};

          &:hover {
            background-color: ${darkTheme.colors.contentBgColor};
          }
        }
      }
    }
  }

  &.light-mode {
    background-color: ${lightTheme.colors.backgroundColor};
    /* transition: background-color .5s ease; */

    input {
      caret-color: ${lightTheme.colors.inputPlaceholderColor};
      color: ${lightTheme.colors.inputPlaceholderColor};;
    }

    /* ------------------------------------- */
    /* Wallet  */
    /* ------------------------------------- */
    .wallet-button-bg-color {
      background-color: ${lightTheme.colors.walletButtonBgColor};
    }
    .wallet-etherscan-color {
      color: ${lightTheme.colors.headingColor};
    }
    .wallet-container {
      background-color: ${lightTheme.colors.white};
      border: 2px solid transparent;
      &.active {
        border: 2px solid ${lightTheme.colors.primary};
      }
    }
    .wallet-popper-inner-bg-color {
      background-color: ${lightTheme.colors.contentInnerInnerBgColor};
    }
    .wallet-name {
      color: ${lightTheme.colors.headingColor};
    }
    .wallet-eth {
      background: #838BA8;
      // border: 2px solid ${lightTheme.colors.backgroundColor};
      // background: linear-gradient(315deg, #60658B 0%, #878DAC 100%);
    }
    .wallet-matic {
      // border: 2px solid ${lightTheme.colors.backgroundColor};
      background: rgb(130, 71, 229);
      // background: linear-gradient(90deg, rgba(179, 138, 249, 1) 0%, rgba(130, 71, 229, 1) 100%);
    }

    /* ------------------------------------- */
    /* General  */
    /* ------------------------------------- */
    & .styled-hamburger {
      rect {
        stroke: ${lightTheme.colors.hamburgerIcon};
        fill: ${lightTheme.colors.hamburgerIcon};
      }
    }

    & .styled-fixed-sidebar {
      background-color: ${lightTheme.colors.sidebarBackgroundColor};
      box-shadow: 0 4px 20px #00000016;
      /* transition: background-color .5s ease; */
    }

    & .styled-content {
      background-color: ${lightTheme.colors.contentBgColor};
    }

    & .content-inner-bg-color {
      background-color: ${lightTheme.colors.contentInnerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .content-inner-inner-bg-color {
      background-color: ${lightTheme.colors.contentInnerInnerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .announcement-bg-color {
      background-color: ${lightTheme.colors.annoncementBgColor};
      /* transition: background-color .5s ease; */
    }

    & .primary-bg-color {
      background-color: ${lightTheme.colors.primary};
      /* transition: background-color .5s ease; */
    }

    & .primary-color {
      color: ${lightTheme.colors.primary};
      /* transition: color .5s ease; */
    }

    & .primary-text-color {
      color: ${lightTheme.colors.primaryTextColor};
      /* transition: color .5s ease; */
    }

    & .warning-text-color {
      color: ${lightTheme.colors.warningTextColor};
      /* transition: color .5s ease; */
    }

    & .sub-text-color {
      color: ${lightTheme.colors.subTextColor};
      /* transition: color .5s ease; */
    }

    & .heading-color {
      color: ${lightTheme.colors.headingColor};
      /* transition: color .5s ease; */
    }

    & .selected-color {
      color: ${lightTheme.colors.selectedColor};
      border-color: ${lightTheme.colors.selectedColor};
    }

    & .unselected-color {
      color: ${lightTheme.colors.unselectedColor};
      border-color: ${lightTheme.colors.unselectedColor};
    }

    & .link-hover-color {
      color: ${lightTheme.colors.linkHoverColor};
      border-color: ${lightTheme.colors.linkHoverColor};
    }

    & .footer-bg-color {
      background-color: ${lightTheme.colors.footerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .primary-button-bg-color {
      background-color: ${lightTheme.colors.primaryButtonBgColor};
      border-color: ${lightTheme.colors.primaryButtonBgColor};
      /* transition: background-color .5s ease; */
    }
    & .primary-button-disabled-bg-color {
      background-color: ${lightTheme.colors.primaryButtonDisabledBgColor} !important;
      cursor: not-allowed;
      /* transition: background-color .5s ease; */
    }
    & .primary-button-color {
      color: ${lightTheme.colors.white};
      /* transition: color .5s ease; */
    }
    & .secondary-button-bg-color {
      background-color: ${lightTheme.colors.secondaryButtonBgColor};
      border: 1px solid ${lightTheme.colors.secondaryButtonBgColor};
      /* transition: background-color .5s ease; #e8f8f3 */
    }

    & .nav-button-bg-color {
      background-color: ${lightTheme.colors.backgroundColor};
      border: 1px solid #e0e0e0;
    }

    & .clean-style-button {
      background-color: transparent;
      border: none;
    }

    & .secondary-button-bg-color:hover {
      border: 1px solid ${lightTheme.colors.primary};
      /* transition: background-color .5s ease; #e8f8f3 */
    }
    & .secondary-button-color {
      color: ${lightTheme.colors.primary};
      /* transition: color .5s ease; */
    }

    & .secondary-button{
      background-color: ${lightTheme.colors.backgroundColor};
      color: ${lightTheme.colors.ichiBalanceButtonBgColor};
    }

    & .secondary-button-disabled{
      background-color: ${lightTheme.colors.backgroundColor};
      color: ${lightTheme.colors.ichiBalanceButtonBgColor};
      opacity: 60%;
      cursor: not-allowed;
    }

    & .deposit-button{
      background-color: ${lightTheme.colors.ichiBalanceButtonBgColor};
      color: ${lightTheme.colors.white};
    }

    & .inner-box-bg-color {
      background-color: ${lightTheme.colors.modalBgColor};
      /* transition: background-color .5s ease; */
    }
    & .inner-box-shadow, .inner-box {
      background-color: ${lightTheme.colors.modalBgColor};
      box-shadow: 0 2px 40px 0 rgba(0, 56, 124, 0.15);
      /* transition: background-color .5s ease; */
    }

    & .hr-bg-color {
      background-color: ${lightTheme.colors.hrBgColor};
      /* transition: background-color .5s ease; */
    }

    & .text-spinner {
      & div.loader {
        border: 4px solid rgba(255, 255, 255, 0.2);
        border-left: 4px solid;
        animation: load 1s infinite linear;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        color: ${lightTheme.colors.spinnerColor};
      }
    }

    & .topbar-bg-color {
      background-color: ${lightTheme.colors.backgroundColor};
      /* transition: background-color .5s ease; */
    }

    & .sub-text{
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.01px;
      line-height: 15px;
      color: ${lightTheme.colors.subTextColor};
    }

    /* ------------------------------------- */
    /* Elements  */
    /* ------------------------------------- */
    & .section-description {
      display: flex;
      justify-content: left;
      margin: 20px 0;
      border-radius: 8px;
      padding: 30px;
      background-color: ${lightTheme.colors.contentInnerBgColor};
      color: ${lightTheme.colors.primaryTextColor};
    }

    & .error-message{
      background-color: rgba(230, 34, 123, 0.1);
      color: #e6227b;
    }

    /* ------------------------------------- */
    /* Tooltips  */
    /* ------------------------------------- */
    & .tooltip-top {
      bottom: 100%;
      padding-bottom: 9px;

      &::after {
        border-top: 9px solid ${lightTheme.colors.inputBorderColor};
        bottom: 0;
      }
    }

    & .tooltip-bottom {
      top: 100%;
      padding-top: 9px;

      &::after {
        border-bottom: 9px solid ${lightTheme.colors.inputBorderColor};
        top: 0;
      }
    }

    & .tooltip-left {
      right: 100%;
      padding-right: 9px;

      &::after {
        border-left: 9px solid ${lightTheme.colors.inputBorderColor};
        right: 0;
      }
    }

    & .tooltip-right {
      left: 100%;
      padding-left: 9px;

      &::after {
        border-right: 9px solid ${lightTheme.colors.inputBorderColor};
        left: 0;
      }
    }

    & .tooltip-bubble {
      color: ${lightTheme.colors.white};
      background-color: ${lightTheme.colors.contentBgColor};
    }

    & .tooltip-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      flex: 0 0 14px;
      @media screen and (max-width: 970px) {
        display: none;
      }
      & img {
        margin-left: 0px;
      }
    }

    & .tooltip-message {
      background-color: ${lightTheme.colors.modalBgColor};
      border: ${lightTheme.colors.inputBorder};
      color: ${lightTheme.colors.textColor};
      box-shadow: ${lightTheme.colors.primaryBoxShadow};
    }


    /* ------------------------------------- */
    /* tabs  */
    /* ------------------------------------- */
    & .tabs-container {
      & .rc-tabs-nav-wrap {
        border-bottom: 1px solid ${lightTheme.colors.modalBgColor};
        &  .rc-tabs-tab {
          color: ${lightTheme.colors.inactiveTabColor};
          &.rc-tabs-tab-active {
            color: ${lightTheme.colors.activeTabColor};
          }
        }
        & .rc-tabs-ink-bar {
          background: ${lightTheme.colors.sidebarItemSelectedColor};
        }
      }

      // This hides the 'More' button on Firefox
      & .rc-tabs-nav-operations {
        & .rc-tabs-nav-more {
          display: none;
        }
      }
    }
    & .btn-view-all {
      color: ${lightTheme.colors.btnViewAllColor};
    }

    /* ------------------------------------- */
    /* Table  */
    /* ------------------------------------- */
    & .table-border-bottom, .table-border-bottom td {
      border-bottom: ${lightTheme.colors.tableBorderBottom};
      /* transition: border-bottom .5s ease; */
    }
    & .treasury-table-sub-text-two-color {
      color: ${lightTheme.colors.treasuryTableSubTextTwoColor};
      /* transition: color .5s ease; */
    }

    & .table-vaults {
      & thead {
        background-color: ${lightTheme.colors.backgroundColor};
      }
      & tr td {
        border-bottom: ${lightTheme.colors.tableBorderBottom};
      }
    }
    & .bordered {
      border: ${lightTheme.colors.tableBorderBottom};
    }


    /* ------------------------------------- */
    /* ICHI Balance  */
    /* ------------------------------------- */
    & .ichi-balance-bg-color {
      background-color: ${lightTheme.colors.ichiBalanceBgColor};
      /* transition: background-color .5s ease; */
    }
    & .ichi-balance-header-border-bottom {
      border-bottom: ${lightTheme.colors.ichiBalanceHeaderBorderBottom};
      /* transition: background-color .5s ease; */
    }
    & .ichi-balance-button-bg-color {
      background-color: ${lightTheme.colors.ichiBalanceButtonBgColor};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* search  */
    /* ------------------------------------- */
    & .styled-search {
    }

    & .input-border {
      border: ${lightTheme.colors.inputBorder};
      /* transition: border .5s ease; */
    }
    & .input-bg-color {
      background-color: ${lightTheme.colors.inputBgColor};
      /* transition: background-color .5s ease; */
    }
    & .input-label-color {
      color: ${lightTheme.colors.inputLabelColor};
      /* transition: color .5s ease; */
    }
    & .input-placeholder-color {
      color: ${lightTheme.colors.inputPlaceholderColor};
      /* transition: color .5s ease; */
    }

    /* ------------------------------------- */
    /* Bottombar  */
    /* ------------------------------------- */
    & .styled-bottombar {
      background-color: ${lightTheme.colors.backgroundColor};
    }

    /* ------------------------------------- */
    /* Sidebar  */
    /* ------------------------------------- */
    & .styled-top-item {
      & a {
        color: ${lightTheme.colors.sidebarTextColor};
        /* transition: color .5s ease; */
        &.active {
          color: ${lightTheme.colors.sidebarTextSelectedColor};
          /* transition: color .5s ease; */
        }
      }
    }

    & .styled-bottom-items {
      border-top: 1px solid ${lightTheme.colors.borderColor};
      & a {
        color: ${lightTheme.colors.sidebarTextColor};
        /* transition: color .5s ease; */
      }
    }

    /* ------------------------------------- */
    /* Farm  */
    /* ------------------------------------- */
    & .coin-name {
      display: flex;
      margin-top: 0px;
      height: 17px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.02px;
      line-height: 19px;
      white-space: nowrap;
      color: ${lightTheme.colors.primaryTextColor};
    }

    & .sub-name {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.26px;
      line-height: 19px;
      color: ${lightTheme.colors.primaryTextColor};
    }

    & .styled-go-back {
      margin-top: 10px;

      & a {
        height: 20px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.02px;
        line-height: 19px;
        color: ${lightTheme.colors.primary};
      }
    }

    & .vault-header {
      margin: auto; // centers the div
      width: 550px;
      background-color: ${lightTheme.colors.contentBgColor};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 970px) {
        margin-top: 10px;
      }
      @media screen and (max-width: 610px) {
        width: 100%;
      }
    }

    & .vault-title {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.02px;
      line-height: 26px;
      white-space: nowrap;
      color: ${lightTheme.colors.primaryTextColor};
    }

    & .vault-box{
      margin: auto;
      margin-top: 20px;
      width: 600px;
      border-radius: 10px;

      @media screen and (max-width: 610px) {
        width: 100%;
      }
    }

    & .vault-description{
      margin: 20px auto;
      display: block;
      max-width: 600px;

      @media screen and (max-width: 610px) {
        width: 100%;
      }
    }

    & .bg-bf{
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(269.63deg, #FFE1B7 4.13%, #FFF4D2 64.07%, #FFF9DB 99.84%);
      gap: 4px;
      border-radius: 30px;
    }

    & .bg-image-bf{
      background-image: url('../../assets/image/Gradient Bg.png');
      background-size: 100% 100%;
    }

    & .chainlink-logo{
      content:url('${badgeSrc}Chainlink.svg');
    }
    & .bril-logo{
      content:url('../../assets/image/bril-logo-golden.svg');
    }
    & .yield-iq-logo{
      content:url('${badgeSrc}Yield-IQ.png');
    }
    & .yield-iq-div{
      background-color: #222222;
    }
    & .styled-farm-sub-header-text {
      color: ${lightTheme.colors.headingColor};
      /* transition: color .5s ease; */
    }
    & .styled-no-rewards-button {
      background-color: ${lightTheme.colors.noRewardsButtonColor};
      /* transition: background-color .5s ease; */
    }
    & .styled-claim-rewards-button-color {
      background-color: ${lightTheme.colors.primaryButtonBgColor};
      /* transition: background-color .5s ease; */
    }
    & .vault-banner-border {
      border: 1px solid ${lightTheme.colors.vaultBannerBorderColor};
      box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    }


    /* ------------------------------------- */
    /* Treasury  */
    /* ------------------------------------- */
    & .treasury-position-box {
      border: ${lightTheme.colors.treasuryPositionBorder};
      background-color: ${lightTheme.colors.treasuryPositionBgColor};
      &:hover {
        box-shadow: 0 2px 40px 0 rgba(0, 56, 124, 0.15);
        border: none;
      }
    }
    & .treasury-position-name-color {
      color: ${lightTheme.colors.treasuryPositionNameColor};
      /* transition: color .5s ease; */
    }

    /* ------------------------------------- */
    /* Icons  */
    /* ------------------------------------- */
    & .icon-plus {
      content: url(${IconPlusBlack});
    }
    & .icon-theme-switcher {
      content: url(${lightTheme.icons.theme});
      height: 30px;
      width: 30px;
    }
    & .icon-theme-switcher-mobile {
      content: url(${lightTheme.icons.themeMobile});
      width: 60px;
      height: 60px;
      @media (min-width: 799px) {
        display: none;
      }
    }
    & .icon-preferences-active {
      content: url(${lightTheme.icons.preferences.active});
    }
    & .icon-preferences {
      content: url(${lightTheme.icons.preferences.inactive});
      &.active {
        content: url(${darkTheme.icons.preferences.active});
      }
      @media (max-width: 800px) {
        display: none;
      }
    }
    & .icon-preferences-mobile {
      content: url(${lightTheme.icons.preferences.inactiveMobile});
      width: 80px;
      height: 80px;
      @media (min-width: 799px) {
        display: none;
      }
    }
    & .icon-new-window {
      content: url(${lightTheme.icons.newWindow});
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      flex: 0 0 15px;
      margin-left: 5px;
      // margin-bottom: 0;
    }
    & .icon-new-window-white {
      content: url(${lightTheme.icons.newWindowWhite});
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      flex: 0 0 15px;
      margin-left: 5px;
      // margin-bottom: 0;
    }
    & .icon-close {
      content: url(${lightTheme.icons.close});
    }
    & .icon-ichi-owned {
      content: url(${lightTheme.icons.ichiOwned});
    }
    & .icon-ichi {
      content: url(${lightTheme.icons.ichiOwned});
    }
    & .icon-ichi-mobile {
      content: url(${lightTheme.icons.ichiMobile});
      height: 34px;
      width: 34px;
    }
    & .icon-lp {
      content: url(${lightTheme.icons.lp});
    }
    & .icon-eth {
      content: url(${lightTheme.icons.eth});
    }
    & .icon-eth-wallet {
      content: url(${lightTheme.icons.ethWhite});
    }
    & .icon-matic-wallet {
      content: url(${lightTheme.icons.maticWhite});
      margin-right: 3px;
    }
    & .icon-Token18 {
      content: url(${lightTheme.icons.eth});
    }
    & .icon-weth {
      content: url(${lightTheme.icons.eth});
    }
    & .icon-wbtc {
      content: url(${lightTheme.icons.wbtc});
    }
    & .icon-renFIL {
      content: url(${lightTheme.icons.renFIL});
    }
    & .icon-test-renfil {
      content: url(${lightTheme.icons.renFIL});
    }
    & .icon-help {
      content: url(${lightTheme.icons.help});
    }

    // --------------------------------
    // NOTE: For Kovan testing
    // --------------------------------
    & .icon-test_ichi {
      content: url(${lightTheme.icons.ichiOwned});
    }
    & .icon-test_weth {
      content: url(${lightTheme.icons.eth});
    }

    /* ------------------------------------- */
    /* Wallet Popper  */
    /* ------------------------------------- */
    & .wallet-popper-header-border-bottom {
      border-bottom: ${lightTheme.colors.walletPopperHeaderBorderBottom};
      /* transition: background-color .5s ease; */
    }
    & .wallet-popper-text-color {
      background-color: ${lightTheme.colors.walletPopperTextColor};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* Select Token */
    /* ------------------------------------- */
    & .styled-select-token {
      color: ${lightTheme.colors.subTextColor};
      & select {
        background-color: ${lightTheme.colors.contentInnerBgColor};
        /* transition: background-color .5s ease; */
      }

      & .select__control {
        background-color: ${lightTheme.colors.contentInnerBgColor};
        /* transition: background-color .5s ease; */
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Roboto', sans-serif;
  }

  .farm-name{
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
  }

  .bold{
    font-weight: 600;
  }

  section {
    position: relative;
  }

  .drawer-content-wrapper {
    @media (max-width: 767px) {
      width: 300px!important;
    }
    .drawer-content {
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li{
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          a{
            font-size: 20px;
            font-weight: 400;
            color: #343d48;
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              color: #2aa275;
            }
            &:before{
              content: '';
              width: 7px;
              height: 7px;
              background: #2aa275;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }
          &.is-current {
            a {
              color: #2aa275;
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
      .navbar_drawer_button button{
        width: 100%;
      }
    }

    .reusecore-drawer__close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
      &:before{
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: #2aa275;
        transform: rotate(45deg);
        display: block;
      }
    }
  }
`;
